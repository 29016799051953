import restApi from "@/app/lib/services/restApi";

interface AddContactProps {
  email: string;
  content: string;
  subject: string;
  blob?: Blob;
}

export const AddContact = ({
  email,
  content,
  subject,
  blob,
}: AddContactProps) => {
  const form = new FormData();
  form.append("email", email);
  form.append("content", content);
  form.append("subject", subject);
  if (blob) {
    form.append("attachment", blob);
  }

  return restApi.post("/api/v1/mail/contact", form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
