import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const Loader = ({ isFullScreen = false }) => {
  return (
    <Box
      sx={{
        ...(isFullScreen && {
          width: "100%",
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }),
      }}
    >
      <CircularProgress disableShrink color="secondary" />
    </Box>
  );
};
