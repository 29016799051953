"use client";

import React from "react";

import { ContactForm } from "./ContactForm";
import { NavFooter } from "./NavFooter";
import { Box, Container, Divider, Typography } from "@mui/material";

interface ContactContainerProps {
  page: string;
}

export const ContactContainer = ({ page }: ContactContainerProps) => {
  return (
    <Box id="kontakt" sx={{ background: "#161433", color: "white" }}>
      <Container
        maxWidth="lg"
        sx={{ py: 10, display: "flex", flexDirection: "column", gap: 4 }}
      >
        <Typography variant="h2">Kontakt</Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          gap={{ xs: 8, lg: 24 }}
        >
          <Box sx={{ flex: 1 }}>
            <ContactForm page={page} />
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              width: "1px",
              height: "auto",
              background: "#32304b",
              display: { xs: "none", lg: "block" },
            }}
          />
          <Divider
            orientation="horizontal"
            sx={{
              width: "auto",
              height: "1px",
              background: "#32304b",
              display: { xs: "block", lg: "none" },
            }}
          />
          <Box sx={{ flex: 1 }}>
            <NavFooter />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
