"use client";

import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Loader } from "@/app/lib/components/commons/Loader";
import { route } from "@/app/lib/utils/routes";
import { emiteoAddress } from "@/app/lib/utils/commonHelpers";
import { AddContact } from "@/app/lib/network/mail/AddContact";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/navigation";
import { sendGAEvent } from "@next/third-parties/google";
import Link from "next/link";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";

const schema = yup.object().shape({
  name: yup.string().required("Należy podać imię i nazwisko"),
  subject: yup.string().required("Należy podać temat"),
  email: yup
    .string()
    .required("Należy podać email")
    .email("Należy podać poprawny adres email"),
  content: yup.string().required("Należy podać treść wiadomości"),
  checkedRegulations: yup.boolean().oneOf([true]),
});

export const ContactForm = ({ page }: { page: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async (contactForm: yup.InferType<typeof schema>) => {
    sendGAEvent("event", "formSubmitted", {
      value: `${page} | Click Wyślij wiadomość`,
    });
    setIsLoading(true);

    AddContact({
      email: contactForm.email,
      subject: contactForm.subject,
      content: `${contactForm.name} przesyła wiadomość <br /> ${contactForm.content}`,
    })
      .then(() => {
        router.push(route.thankYouForContact);
      })
      .catch((err) => {
        alert("Błąd podczas wysyłania wiadomości. Spróbuj ponownie później.");
        throw err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <Box height="100%">
        <Loader isFullScreen />
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <TextField
        fullWidth
        placeholder="Imię i Nazwisko"
        {...register("name")}
        error={!!errors.name}
        helperText={errors.name?.message}
      />
      <TextField
        fullWidth
        placeholder="E-mail"
        {...register("email")}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <TextField
        fullWidth
        placeholder="Temat"
        {...register("subject")}
        error={!!errors.subject}
        helperText={errors.subject?.message}
      />
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          fullWidth
          placeholder="Treść wiadomości"
          multiline
          rows={5}
          {...register("content")}
          error={!!errors.content}
          helperText={errors.content?.message}
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: !!errors.checkedRegulations ? "#f44336" : "white",
                "&.Mui-checked": {
                  color: !!errors.checkedRegulations ? "#f44336" : "white",
                },
              }}
              {...register("checkedRegulations")}
              color="primary"
              size="small"
            />
          }
          label={
            <Typography variant="body3a">
              Zapoznałem się i akceptuję Politykę Prywatności&nbsp;Emiteo
            </Typography>
          }
        />
        <Typography variant="body5a">
          Administratorem Państwa danych osobowych jest Emiteo sp. z o.o. z
          siedzibą w {emiteoAddress}. Państwa dane osobowe przetwarzane są w
          celu udzielenia odpowiedzi na wiadomość wysłaną za pomocą formularza
          kontaktowego. Szczegółowe postanowienia dotyczące danych osobowych
          znajdują się w Polityce Prywatności -{" "}
          <Link
            href={route.newsletterRegualations}
            target="_blank"
            style={{ color: "white" }}
            rel="noreferrer"
          >
            zapoznaj się z treścią
          </Link>
        </Typography>
      </Box>
      <Button variant="contained" type="submit">
        Wyślij wiadomość
      </Button>
    </Box>
  );
};
