import React from "react";
import TW_SVG from "@/app/lib/static/svg/social/tw.svg";
import DISCORD_SVG from "@/app/lib/static/svg/social/icon-discord.svg";
import Link from "next/link";
import {
  FooterItem,
  footerItems,
} from "@/app/lib/components/commons/Layout/Nav/Nav.repository";
import { route } from "@/app/lib/utils/routes";
import { Box, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const NavFooter: React.FunctionComponent = () => {
  const renderNavItems = (items: FooterItem[]) =>
    items.map(({ name, url }, key) => (
      <Box key={key}>
        <Link href={url} style={{ textDecorationColor: "white" }}>
          <Typography variant="body2a" sx={{ color: "white" }}>
            {name}
          </Typography>
        </Link>
      </Box>
    ));

  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign={{ xs: "center", lg: "start" }}
      gap={3}
    >
      <Box
        display="flex"
        justifyContent={{ xs: "center", lg: "flex-start" }}
        gap={2}
      >
        <Link
          target="_blank"
          href={route.facebook}
          className="footer__icon"
          rel="noreferrer"
        >
          <FacebookIcon sx={{ color: "white" }} />
        </Link>
        <Link
          target="_blank"
          href={route.linkedin}
          className="footer__icon"
          rel="noreferrer"
        >
          <LinkedInIcon sx={{ color: "white" }} />
        </Link>
        <Link
          target="_blank"
          href={route.discord}
          className="footer__icon"
          rel="noreferrer"
        >
          <DISCORD_SVG />
        </Link>
        <Link
          target="_blank"
          href={route.instagram}
          className="footer__icon"
          rel="noreferrer"
        >
          <InstagramIcon sx={{ color: "white" }} />
        </Link>
        <Link
          target="_blank"
          href={route.youtube}
          className="footer__icon"
          rel="noreferrer"
        >
          <YouTubeIcon sx={{ color: "white" }} />
        </Link>
        {route.twitter && (
          <Link
            target="_blank"
            href={route.twitter}
            className="footer__icon"
            rel="noreferrer"
          >
            <TW_SVG />
          </Link>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {renderNavItems(footerItems)}
      </Box>

      <Typography variant="body5a">
        Emiteo sp. z o.o. (KRS nr 0000820021) z siedzibą w Koszalinie, adres:
        ul. Wojska Polskiego 24-26, 75-712 Koszalin, jest dostawcą usług
        finansowania społecznościowego i posiada zezwolenie na świadczenie usług
        finansowania społecznościowego (crowdfunding inwestycyjny) wydane przez
        Komisję Nadzoru Finansowego (data wydania decyzji - 27.10.2023). Usługi
        finansowania społecznościowego, oferowane na platformie finansowania
        społecznościowego Emiteo, nie są objęte systemem gwarancji depozytów
        ustanowionym zgodnie z dyrektywą 2014/49/UE. Zbywalne papiery
        wartościowe lub instrumenty dopuszczone na potrzeby finansowania
        społecznościowego, nabyte za pośrednictwem platformy finansowania
        społecznościowego Emiteo, nie są objęte systemem rekompensat dla
        inwestorów ustanowionym zgodnie z dyrektywą 97/9/WE.
      </Typography>
    </Box>
  );
};
