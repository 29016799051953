import { route } from "@/app/lib/utils/routes";

export interface FooterItem {
  name: string;
  url: string;
  isAnchor: boolean;
}

export const footerItems: FooterItem[] = [
  {
    name: "O nas",
    url: route.aboutUs,
    isAnchor: false,
  },
  {
    name: "Ryzyka inwestycyjne",
    url: route.investmentRisks,
    isAnchor: false,
  },
  {
    name: "Regulamin",
    url: route.termsAndConditions,
    isAnchor: false,
  },
  {
    name: "Regulamin newslettera",
    url: route.newsletterRegualations,
    isAnchor: false,
  },
  {
    name: "Polityka prywatności",
    url: route.privacyPolicy,
    isAnchor: false,
  },
  {
    name: "Regulamin rozpatrywania skarg",
    url: route.considerationComplaint,
    isAnchor: false,
  },
  {
    name: "Dodatkowe informacje dla inwestorów",
    url: route.informationInvestors,
    isAnchor: false,
  },
  {
    name: "Regulamin zapobiegania konfliktom interesów",
    url: route.conflictInterest,
    isAnchor: false,
  },
];
